import React from 'react';
import Layout from '../components/layout/Layout';
import AddressHistory from '../components/AddressHistory';
import "../style/styles.css"
import { BackButton } from '../components/ButtonDesign';

const History = () => (
  <Layout>
    <div className="px-2">
      <div className="w-full h-full px-2 md:px-32 mx-auto flex justify-center items-center">
        <div className='w-full '>
          <AddressHistory />
          <BackButton />
       </div>
      </div>
    </div>
  </Layout>
);

export default History;