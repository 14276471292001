import React from "react"
import '../css/style.css';

class AddressHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // balance 
      data: [],
      asset: null,
      quantity: null,
      unconfirmed_quantity: null,
      // history
      history_length: null,
      history_data: [],
      loading: true
    }
  }

  async componentDidMount() {
    // balance
    await fetch('https://mpchain.info/api/balance/MC4PVjnEF2ZXSdeGGA3KfhDtNq9F9FGXEJ/XMP', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        asset: data.asset,
        quantity: data.quantity,
        unconfirmed_quantity: data.unconfirmed_quantity,
      })
    })
    .catch((error) => {
    });

    // history
    await fetch('https://mpchain.info/api/history/MC4PVjnEF2ZXSdeGGA3KfhDtNq9F9FGXEJ', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(result => {
      let list = [];
      for(let i = 0; i < 20; i++){
        let link = "https://mpchain.info/tx/" + result.data[i].tx_hash;
        list.push(
          <div className="mb-4 border-4 sm:border-6 border-white-800">
            <table className="w-full table-fixed border-collapse border border-white-800">
              <thead>
                <tr>
                  <th className="bg-pink-400 w-full border border-white-800">&#x1f4aa; Destination</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-pink-400 break-words border border-white-800">{result.data[i].destination}</td>
                </tr>
              </tbody>
            </table>
            <table className="w-full table-fixed border-collapse border border-white-800">
              <thead>
                <tr>
                  <th className="bg-pink-400 w-full border border-white-800">&#x1f4aa; TX Hash</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-pink-400 break-words border border-white-800">
                    <a href={ link }>&#x1f440; {result.data[i].tx_hash}</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className=" w-full table-fixed border-collapse border border-white-800">
              <thead>
                <tr>
                  <th className="bg-pink-400 w-1/3 border border-white-800">&#x1f4aa; Quantity</th>
                  <th className="bg-pink-400 w-1/3 border border-white-800">&#x1f4aa; Timestamp</th>
                  <th className="bg-pink-400 w-1/3 border border-white-800">&#x1f4aa; Block Index</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-pink-400 border border-white-800">{result.data[i].quantity}</td>
                  <td className="bg-pink-400 border border-white-800">{result.data[i].timestamp}</td>
                  <td className="bg-pink-400 border border-white-800">{result.data[i].block_index}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
        );
      }
      this.setState({ history_data: list, history_length: result.total, loading: false });
    })
    .catch((error) => {
    });
  }

  render() {
    if(this.state.loading) {
      return(
        <div className="w-full my-48">
          <div class="loader">Loading...</div>
        </div>
      );
    }
    return (
      <div className="mt-8 lg:mt-0 mb-2 lg:mb-0 text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center">
        <div className="w-full my-8">
          <p className="text-2xl text-left">TX History</p>
          <p className="text-xs text-left mb-2">Showing 1 - 20 out of {this.state.history_length} <a href="https://mpchain.info/address/MC4PVjnEF2ZXSdeGGA3KfhDtNq9F9FGXEJ"> (&#x1f449; more)</a></p>
          <div className="text-xs sm:text-base">
            {this.state.history_data}
          </div>
        </div>
      </div>
    )
  }
}

export default AddressHistory;